// 自定义node服务端缓存 2024-11-16
// 原理：通过fs写入文件实现
// import Redis from 'ioredis'; // 操作redis
// const LRU = require('lru-cache');
let node_fs: any = null;
let path: any = null;
if (process.server) {
  node_fs = require('fs');
  path = require('path');
}
// import LRUCache from 'lru-cache';

// const cache: any = new LRU({
//   max: 2000, // 缓存最大条目数
//   maxAge: 1000 * 3600 * 24, // 缓存时间600秒 单位：毫秒 3600秒=1小时 *24 表示24小时
// });

export default (ctx: any, inject: any) => {
  // 要写入的数据
  // const data = '这里是要写入文件的内容';

  // 获取当前工作目录
  const currentDirectory = process.cwd();
  console.log('20-zsCache', currentDirectory);

  const dirPath = currentDirectory + '/zs'; // 'zhangsan/cache';

  // 完整文件路径(path+文件名+.txt)
  let getFilePath = (fileName: string) => {
    let filePath = path.join(dirPath, fileName + '.txt');
    return filePath;
  };
  // 删除
  let deleteFile = (key: string) => {
    let filePathDelete = getFilePath(key);
    // 删除文件
    node_fs.unlink(filePathDelete, (err: any) => {
      if (err) throw err;
      console.log('1003-文件已删除: ', filePathDelete);
    });
  };

  let zsCache = {
    /**
     * 写入
     * key 缓存key(文件名称)
     * value 缓存内容,类型:字符串
     * time 缓存时间(单位:毫秒)
     * */
    set: (key: string, value: string, time: number) => {
      let data = {
        expireTime: Date.now() + time, // 缓存过期时间
        data: value,
      };
      // 异步写入文件
      node_fs.writeFile(getFilePath(key), data, 'utf8', (err: any) => {
        if (err) {
          console.error('1001-文件写入失败, key: ', key);
          return false;
        }
        console.log('文件写入成功');
      });
    },
    get: (key: string) => {
      // 文件读取（同步）
      return new Promise((resolve, reject) => {
        node_fs.readFileSync(
          getFilePath(key),
          'utf8',
          (err: any, data: any) => {
            if (err) {
              console.error(`1002-文件读取失败: ${err.message}`);
              resolve(false);
            }
            console.log('1002-文件读取成功: ', data); // 文件内容
            resolve(data);
          }
        );
      });
    },
  };

  // 注入cache到Nuxt上下文
  inject('zsCache', zsCache);

  // 创建 Redis 实例，这里假设 Redis 运行在 localhost:6379
  // const redis = new Redis();

  // const client = Redis.createClient(); // 默认连接到 127.0.0.1:6379
  // client.set('key', 'value'); // 将数据写入内存（Redis）

  // client.quit(); // 关闭与Redis的连接
  // 使用 Redis 实例进行操作，例如监听特定的 channel
  // redis.subscribe('my-channel');

  // redis.on('message', (channel, message) => {
  //   // 接收到消息后，根据业务需求进行处理
  //   // 例如：更新 Vuex store 中的状态
  //   ctx.store.commit('someMutation', message);
  // });

  // 将 Redis 实例注入到 Nuxt 上下文中，以便在其他地方使用
  // inject('redis', redis);

  // 注入cache到Nuxt上下文
  // inject('zhangsanCache', client);
};
